import React from 'react';
import { ReactComponent as ChatIcon } from '../../assets/images/sidebar-svgrepo-com.svg';
import { ReactComponent as SettingsIcon } from '../../assets/images/new-svgrepo-com.svg';
import MessageList from './MessageList';
import ChatInput from './ChatInput';
import ChatModelSelector from './ChatModelSelector';

function ChatWindow({
  isSidebarVisible,
  onShowSidebarTooltip,
  showSidebarTooltip,
  setIsSidebarVisible,
  showTopChatTooltip,
  setShowTopChatTooltip,
  dropdownRef,
  showModelDropdown,
  setShowModelDropdown,
  selectedModel,
  availableModels,
  handleModelSelect,
  messages,
  inputText,
  handleInputChange,
  handleSendMessage,
}) {
  return (
    <>
      {/* Top Chat Options */}
      <div className="top-chat-options">
        {!isSidebarVisible && (
          <>
            <div
              className="sidebar-open-icon-container"
              onMouseEnter={() => onShowSidebarTooltip(true)}
              onMouseLeave={() => onShowSidebarTooltip(false)}
              onClick={() => setIsSidebarVisible(true)}
            >
              <ChatIcon className="sidebar-icon chat-svg-icon" />
              {showSidebarTooltip && (
                <div className="sidebar-tooltip fade-in">Open Sidebar</div>
              )}
            </div>

            <div
              className="sidebar-chat-icon-container"
              onMouseEnter={() => setShowTopChatTooltip(true)}
              onMouseLeave={() => setShowTopChatTooltip(false)}
            >
              <SettingsIcon className="sidebar-icon settings-svg-icon" />
              {showTopChatTooltip && (
                <div className="chat-tooltip fade-in">New Chat</div>
              )}
            </div>
          </>
        )}

        <ChatModelSelector
          dropdownRef={dropdownRef}
          showModelDropdown={showModelDropdown}
          setShowModelDropdown={setShowModelDropdown}
          selectedModel={selectedModel}
          availableModels={availableModels}
          handleModelSelect={handleModelSelect}
        />
      </div>

      {/* Chat Messages */}
      <div className="chat-messages">
        <div className="chat-messages-inner">
          <MessageList messages={messages} />
        </div>
      </div>

      {/* Chat Input */}
        <ChatInput
          inputText={inputText}
          handleInputChange={handleInputChange}
          handleSendMessage={handleSendMessage}
        />
    </>
  );
}

export default ChatWindow;