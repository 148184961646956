class QuizService {
  constructor({ onMessage }) {
    this.onMessage = onMessage;
    this.ws = null;
    this.isOpen = false;
  }

  connect() {
    return new Promise((resolve, reject) => {
      this.ws = new WebSocket('wss://dev.chromecopilot.ai/ws/generate_quiz/');

      this.ws.onopen = () => {
        console.log('WebSocket connected to QuizService');
        this.isOpen = true;
        resolve(); // Resolve the promise once the connection is open
      };

      this.ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log('WebSocket received message in QuizService:', data);
        if (this.onMessage) {
          this.onMessage(data);
        }
      };

      this.ws.onclose = () => {
        console.log('WebSocket disconnected from QuizService');
        this.isOpen = false;
      };

      this.ws.onerror = (error) => {
        console.error('WebSocket error in QuizService:', error);
        reject(error); // Reject the promise if there's an error
      };
    });
  }

  async sendQuizRequest(messages, token) {
    if (!this.isOpen || this.ws.readyState !== WebSocket.OPEN) {
      console.warn('WebSocket is not open. Attempting to reconnect...');
      await this.connect(); // Reconnect if the WebSocket is not open
    }

    const payload = {
      token: token,
      type: 'generate_quiz',
      messages: messages,
    };

    console.log('Sending quiz generation request:', payload);
    this.ws.send(JSON.stringify(payload));
  }

  close() {
    if (this.ws) {
      console.log('Closing WebSocket connection in QuizService');
      this.ws.close();
    }
  }
}

export default QuizService;