import React, { useRef } from 'react';
import './ChatInput.css';

function ChatInput({ inputText, handleInputChange, handleSendMessage }) {
  const textAreaRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey && inputText.trim() !== '') {
      event.preventDefault(); // Prevent a new line
      handleSendMessage();
    }
  };

  const adjustTextAreaHeight = () => {
    const textArea = textAreaRef.current;
    if (textArea) {
      textArea.style.height = 'auto'; // Reset height to shrink if needed
      textArea.style.height = `${Math.max(textArea.scrollHeight, 39)}px`; // Adjust height based on content
    }
  };

  const handleChange = (event) => {
    handleInputChange(event); // Update input text in parent
    adjustTextAreaHeight(); // Adjust height dynamically
  };

  return (
    <div className="chat-input">
      <textarea
        ref={textAreaRef}
        placeholder="Message Copilot"
        value={inputText}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        className="chat-input-field"
        rows={1}
      />
      <button
        className={`chat-send-button ${inputText ? 'has-text' : ''}`}
        style={{ cursor: inputText ? 'pointer' : 'default' }}
        onClick={handleSendMessage}
      >
        <i className="chat-send-icon bi bi-arrow-up-circle-fill"></i>
      </button>
    </div>
  );
}

export default ChatInput;