import React, { createContext, useContext, useState } from 'react';

const SidebarContext = createContext();

export function SidebarProvider({ children }) {
  const [sidebarContent, setSidebarContent] = useState('');
  const [refreshSidebarFlag, setRefreshSidebarFlag] = useState(false); // Flag to trigger sidebar refresh

  const refreshSidebar = () => {
    setRefreshSidebarFlag((prev) => !prev); // Toggle flag to trigger refresh
  };

  return (
    <SidebarContext.Provider value={{ sidebarContent, setSidebarContent, refreshSidebarFlag, refreshSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
}

// Custom hook for easy access to the context
export function useSidebar() {
  return useContext(SidebarContext);
}