import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './MenuBar.css';
import { useAuth } from '../helpers/AuthProvider';
import mindMapIcon from '../../assets/images/mind-map-icon.svg';
import paradigmLogo from '../../assets/images/logo-inspiration-compass-image-2-removebg-preview.png';

function MenuBar() {
  const navigate = useNavigate();
  const menuBarRef = useRef(null);

  const [showMindMapTooltip, setShowMindMapTooltip] = useState(false);
  const [showSettingsTooltip, setShowSettingsTooltip] = useState(false);
  const [showClassroomTooltip, setShowClassroomTooltip] = useState(false);
  const [showChatTooltip, setShowChatTooltip] = useState(false);
  const [showStatsTooltip, setShowStatsTooltip] = useState(false);

  // Dynamically adjust the height of the menu bar
  useEffect(() => {
    const adjustMenuBarHeight = () => {
      if (menuBarRef.current) {
        const menuBarHeight = window.innerHeight - 30;
        menuBarRef.current.style.height = `${menuBarHeight}px`;
      }
    };

    adjustMenuBarHeight();
    window.addEventListener('resize', adjustMenuBarHeight);

    return () => {
      window.removeEventListener('resize', adjustMenuBarHeight);
    };
  }, []);

  console.log("Rendering MenuBar...");

  return (
    <div className="menu-bar" ref={menuBarRef}>
      {/* Top section for Paradigm Logo */}
      <div className="menu-bar-top-item logo-item">
        <img
          src={paradigmLogo}
          alt="Paradigm Logo"
          className="paradigm-logo"
        />
      </div>

      {/* Middle section for main icons */}
      <div className="menu-bar-middle">
        {/* Mind Map Icon with Tooltip */}
        <div
          className="menu-bar-item"
          tabIndex="0"
          onClick={() => {
            console.log("Opening Mind Map in new tab");
            window.open('https://dev.chromecopilot.ai/mindmap/', '_blank');
          }}
          onMouseEnter={() => setShowMindMapTooltip(true)}
          onMouseLeave={() => setShowMindMapTooltip(false)}
        >
          <img src={mindMapIcon} alt="Mind Map" style={{ width: '20px', height: '20px' }} />
          {showMindMapTooltip && (
            <div className="menu-bar-tooltip">
              Mind Map
            </div>
          )}
        </div>

        {/* Stats Icon with Tooltip */}
        <div
          className="menu-bar-item"
          onClick={() => {
            console.log("Navigating to /studio/stats");
            navigate('/studio/stats');
          }}
          onMouseEnter={() => setShowStatsTooltip(true)}
          onMouseLeave={() => setShowStatsTooltip(false)}
        >
          <i className="bi bi-graph-up" style={{ fontSize: '16px' }}></i>
          {showStatsTooltip && (
            <div className="menu-bar-tooltip">
              Stats
            </div>
          )}
        </div>

        {/* Chat Icon with Tooltip */}
        <div
          className="menu-bar-item"
          onClick={() => {
            console.log("Navigating to /studio/chat");
            navigate('/studio/chat');
          }}
          onMouseEnter={() => setShowChatTooltip(true)}
          onMouseLeave={() => setShowChatTooltip(false)}
        >
          <i className="bi bi-chat-dots" style={{ fontSize: '18px' }}></i>
          {showChatTooltip && (
            <div className="menu-bar-tooltip">
              Chat
            </div>
          )}
        </div>

        {/* Classroom Icon with Tooltip */}
        <div
          className="menu-bar-item"
          onClick={() => {
            console.log("Navigating to /studio/classroom");
            navigate('/studio/classroom');
          }}
          onMouseEnter={() => setShowClassroomTooltip(true)}
          onMouseLeave={() => setShowClassroomTooltip(false)}
        >
          <i className="bi bi-mortarboard" style={{ fontSize: '18px' }}></i>
          {showClassroomTooltip && (
            <div className="menu-bar-tooltip">
              Classroom
            </div>
          )}
        </div>

        {/* Settings Icon with Tooltip */}
        <div
          className="menu-bar-item"
          onMouseEnter={() => setShowSettingsTooltip(true)}
          onMouseLeave={() => setShowSettingsTooltip(false)}
        >
          <i className="bi bi-gear-fill" style={{ fontSize: '17px' }}></i>
          {showSettingsTooltip && (
            <div className="menu-bar-tooltip">
              Settings
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MenuBar;