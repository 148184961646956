import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../helpers/AuthProvider';
import { gsap } from 'gsap';
import './QuizPage.css';

function QuizPage() {
  const { quizId } = useParams(); // Extract classroomId or quizId from URL params
  const { token } = useAuth(); // Access JWT token from AuthProvider
  const [quizTitle, setQuizTitle] = useState('Loading...'); // Placeholder title
  const [quizDifficulty, setQuizDifficulty] = useState(''); // Placeholder difficulty
  const [error, setError] = useState(null); // Error state
  const containerRef = useRef(null); // Ref for GSAP animation

  useEffect(() => {
    // Run GSAP animation when the component mounts
    if (containerRef.current) {
      gsap.fromTo(
        containerRef.current,
        { zIndex: -1, scale: 0.5, opacity: 0 },
        { zIndex: 1, scale: 1, opacity: 1, duration: 0.5, ease: 'power2.out' }
      );
    }

    // Fetch quiz details after rendering the layout
    const fetchQuizDetails = async () => {
      if (!token) {
        console.error('User is not authenticated.');
        setError('Unauthorized. Please log in.');
        return;
      }

      try {
        const response = await axios.get(`/api/quizzes/?classroom_id=${quizId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        const quiz = response.data;

        // Update quiz data dynamically
        setQuizTitle(quiz.title || 'Untitled Quiz');
        setQuizDifficulty(quiz.selected_difficulty || 'Unknown');
      } catch (err) {
        console.error('Failed to fetch quiz details:', err.response?.data || err.message);
        setError('Failed to load quiz details.');
      }
    };

    fetchQuizDetails();
  }, [quizId, token]);

  return (
    <div ref={containerRef} className="quiz-page">
      <div className="quiz-header">
        <h1>{quizTitle}</h1>
      </div>
      <div className="quiz-question">
        <p>Question Placeholder</p>
      </div>
      <div className="quiz-footer">
        <div className={`quiz-difficulty ${quizDifficulty.toLowerCase()}`}>
          <span>{quizDifficulty}</span>
        </div>
        <div className="quiz-navigation">
          <button className="circle-button prev-button">
            <i className="bi bi-arrow-left-circle-fill"></i>
          </button>
          <button className="circle-button next-button">
            <i className="bi bi-arrow-right-circle-fill"></i>
          </button>
        </div>
      </div>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
}

export default QuizPage;