class ClassroomService {
  constructor({ onMessage }) {
    this.onMessage = onMessage;
    this.ws = null;
    this.isOpen = false;

    this.connect();
  }

  connect() {
    this.ws = new WebSocket('wss://dev.chromecopilot.ai/ws/generate_quiz_title/');
    this.ws.onopen = () => {
      console.log('WebSocket connected to ClassroomTitleService');
      this.isOpen = true;
    };

    this.ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('WebSocket received message:', data);
      if (this.onMessage) {
        this.onMessage(data);
      }
    };

    this.ws.onclose = () => {
      console.log('WebSocket disconnected from ClassroomTitleService');
      this.isOpen = false;
    };

    this.ws.onerror = (error) => {
      console.error('WebSocket error in ClassroomTitleService:', error);
    };
  }

  sendRoomTitleRequest(summary, token) {
    const payload = {
      token: token,
      type: 'room_title',
      summary: summary,
    };

    if (this.isOpen && this.ws.readyState === WebSocket.OPEN) {
      console.log('Sending room title request:', payload);
      this.ws.send(JSON.stringify(payload));
    } else {
      console.warn("WebSocket not open. Unable to send room title request.");
    }
  }

  close() {
    if (this.ws) {
      console.log('Closing WebSocket connection');
      this.ws.close();
    }
  }
}

export default ClassroomService;