import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../helpers/AuthProvider'; // Adjust the path to AuthProvider
import debounce from 'lodash.debounce';
import { gsap } from 'gsap';
import './CreateQuiz.css';
import ClassroomService from './QuizTitleService'; // Adjust path as needed
import { useSidebar } from '../Sidebar/SidebarContext';
import { useNavigate } from 'react-router-dom';
import QuizService from './QuizService';

function CreateQuiz() {
  const { id: classroomId } = useParams(); // Classroom ID from URL
  const { token } = useAuth(); // Access JWT token from AuthProvider
  const { refreshSidebar } = useSidebar();
  const { setSidebarContent } = useSidebar();
  const [topic, setTopic] = useState('');
  const [selectedDifficulty, setSelectedDifficulty] = useState(null);
  const [selectedQuizType, setSelectedQuizType] = useState('Default (mixed)');
  const [quote, setQuote] = useState({ text: '', author: '' });
  const [placeholder, setPlaceholder] = useState('');
  const containerRef = useRef(null);
  const maxCharacters = 200;
  const [classroomTitle, setClassroomTitle] = useState('');
  const classroomService = useRef(null);
  const hasGeneratedTitle = useRef(false); // To track generated title
  const typingTimeout = useRef(null); // To track typing timer
  const navigate = useNavigate();
  const quizService = useRef(null);

  const quotes = [
    { text: 'Education is the most powerful weapon which you can use to change the world.', author: 'Nelson Mandela' },
    { text: 'An investment in knowledge pays the best interest.', author: 'Benjamin Franklin' },
    { text: 'Live as if you were to die tomorrow. Learn as if you were to live forever.', author: 'Mahatma Gandhi' },
    { text: 'The roots of education are bitter, but the fruit is sweet.', author: 'Aristotle' },
    { text: 'Education is not preparation for life; education is life itself.', author: 'John Dewey' },
    { text: 'The more that you read, the more things you will know. The more that you learn, the more places you’ll go.', author: 'Dr. Seuss' },
    { text: 'Develop a passion for learning. If you do, you will never cease to grow.', author: 'Anthony J. D’Angelo' },
    { text: 'Change is the end result of all true learning.', author: 'Leo Buscaglia' },
    { text: 'The purpose of education is to replace an empty mind with an open one.', author: 'Malcolm S. Forbes' },
    { text: 'It is the mark of an educated mind to be able to entertain a thought without accepting it.', author: 'Aristotle' },
    { text: 'Education is the movement from darkness to light.', author: 'Allan Bloom' },
    { text: 'Tell me and I forget. Teach me and I remember. Involve me and I learn.', author: 'Benjamin Franklin' },
    { text: 'The beautiful thing about learning is that no one can take it away from you.', author: 'B.B. King' },
    { text: 'You are always a student, never a master. You have to keep moving forward.', author: 'Conrad Hall' },
    { text: 'Wisdom is not a product of schooling but of the lifelong attempt to acquire it.', author: 'Albert Einstein' },
    { text: 'Education is the passport to the future, for tomorrow belongs to those who prepare for it today.', author: 'Malcolm X' },
  ];

  const topicIdeas = [
    'The history of the Roman Empire',
    'The periodic table and its elements',
    'Basics of quantum physics',
    'The American Civil War',
  ];

  useEffect(() => {
    const fetchQuiz = async () => {
      if (!token) {
        console.error('User is not authenticated.');
        return;
      }
    
      try {
        const response = await axios.get(`/api/quizzes/?classroom_id=${classroomId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const quiz = response.data;
    
        setTopic((prev) => (quiz.topic !== prev ? quiz.topic : prev));
        setSelectedDifficulty((prev) =>
          quiz.selected_difficulty !== prev ? quiz.selected_difficulty : prev
        );
        setSelectedQuizType((prev) =>
          quiz.selected_quiz_type !== prev ? quiz.selected_quiz_type : prev
        );
    
        // Store title_generated for conditional checks
        hasGeneratedTitle.current = quiz.title_generated;
        console.log('Fetched title_generated:', quiz.title_generated);
      } catch (error) {
        console.error('Failed to fetch quiz:', error.response?.data || error.message);
      }
    };    

    fetchQuiz();

    const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];
    setQuote(randomQuote);

    const randomTopic = topicIdeas[Math.floor(Math.random() * topicIdeas.length)];
    setPlaceholder(`Enter the topic of the quiz you would like to generate, e.g. ${randomTopic}`);

    gsap.fromTo(
      containerRef.current,
      { zIndex: -1, scale: 0.5, opacity: 0 },
      { zIndex: 1, scale: 1, opacity: 1, duration: 0.5, ease: 'power2.out' }
    );

    classroomService.current = new ClassroomService({
      onMessage: async (data) => {
        if (data.type === 'room_title' && data.title) {
          const sanitizedTitle = data.title.replace(/^"|"$/g, '');
          setClassroomTitle(sanitizedTitle);
          await updateQuizTitle(sanitizedTitle);
    
          try {
            const response = await axios.get(`/api/quizzes/?classroom_id=${classroomId}`, {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            });
            const { title_generated } = response.data;
            hasGeneratedTitle.current = title_generated; // Update local reference
            console.log('Updated title_generated:', title_generated);
          } catch (error) {
            console.error('Failed to fetch title_generated flag:', error.response?.data || error.message);
          }
    
          // Add a delay before refreshing the sidebar
          await new Promise((resolve) => setTimeout(resolve, 500)); // 500ms delay
          await refreshSidebar();
        }
      },
    });    

    return () => classroomService.current?.close();
  }, [classroomId, token]);

  const updateQuizTitle = async (title) => {
    try {
      await axios.patch(`/api/quizzes/?classroom_id=${classroomId}`, {
        title,
        title_generated: true, // Update title_generated alongside the title
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      console.log('Title and title_generated flag updated successfully');
    } catch (error) {
      console.error('Error updating quiz title and title_generated flag:', error.response?.data || error.message);
    }
  };
  
  const updateQuizTopic = async (updatedTopic) => {
    try {
      console.log('Updating topic in backend:', updatedTopic);
      await axios.patch(`/api/quizzes/?classroom_id=${classroomId}`, { topic: updatedTopic }, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      console.log('Topic updated successfully');
    } catch (error) {
      console.error('Failed to update topic in backend:', error.response?.data || error.message);
    }
  };

  const handleInputChange = (e) => {
    const newTopic = e.target.value;
    setTopic(newTopic);
  
    if (typingTimeout.current) clearTimeout(typingTimeout.current);
  
    typingTimeout.current = setTimeout(() => {
      if (newTopic.trim()) {
        updateQuizTopic(newTopic); // Update the backend with the new topic
  
        // Only send room title request if title has not been generated
        if (!hasGeneratedTitle.current) {
          classroomService.current?.sendRoomTitleRequest(newTopic, token);
        }
      }
    }, 2000); // 2 seconds after user stops typing
  };  

  const handleDifficultyClick = (difficulty) => {
    setSelectedDifficulty(difficulty);
    debouncedUpdateQuiz({ selected_difficulty: difficulty });
  };

  const handleQuizTypeClick = (type) => {
    setSelectedQuizType(type);
    debouncedUpdateQuiz({ selected_quiz_type: type });
  };

  useEffect(() => {
    quizService.current = new QuizService({
      onMessage: (data) => {
        if (data.type === 'quiz_data') {
          console.log('Received quiz data:', data.quiz);
          navigate(`/studio/classroom/quiz/${classroomId}`, { state: { quiz: data.quiz } });
        } else if (data.type === 'error') {
          console.error('Quiz generation error:', data.message);
        }
      },
    });
  
    return () => quizService.current?.close();
  }, [classroomId, navigate]);  

  const handleStartQuiz = async () => {
    try {
      // Update the backend to mark the quiz as started
      await axios.patch(
        `/api/quizzes/?classroom_id=${classroomId}`,
        {
          topic,
          selected_difficulty: selectedDifficulty,
          selected_quiz_type: selectedQuizType,
          has_started: true,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      // Trigger the quiz generation via QuizService
      if (quizService.current) {
        const messages = [
          {
            role: 'user',
            content: `Create a ${selectedDifficulty} level quiz about ${topic} with 10 ${selectedQuizType} questions.`,
          },
        ];
        quizService.current.sendQuizRequest(messages, token);
      }
  
      // Navigate to the quiz page (without waiting for the WebSocket response)
      navigate(`/studio/classroom/quiz/${classroomId}`);
    } catch (error) {
      console.error('Error starting quiz:', error.response?.data || error.message);
    }
  };  

  const debouncedUpdateQuiz = debounce(async (updatedFields) => {
    if (!token) {
      console.error('User is not authenticated.');
      return;
    }

    try {
      await axios.patch(`/api/quizzes/?classroom_id=${classroomId}`, updatedFields, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      console.error('Failed to update quiz:', error.response?.data || error.message);
    }
  }, 500);

  const handleAuthorClick = (author) => {
    const searchUrl = `https://www.google.com/search?q=${encodeURIComponent(author)}`;
    window.open(searchUrl, '_blank');
  };

  const isStartQuizEnabled = topic.trim() && selectedDifficulty && selectedQuizType;

  return (
    <div ref={containerRef} className="create-quiz-container">
      <div className="vertical-row">
        <div className="section-container">
          <h2 className="section-heading">Quiz Topic</h2>
          <div className="quiz-topic-tile">
            <textarea
              placeholder={placeholder}
              value={topic}
              onChange={handleInputChange}
              className="quiz-topic-input"
            />
            <div className="character-counter">{topic.length}/{maxCharacters}</div>
          </div>
        </div>

        <div className="section-container">
          <h2 className="section-heading">Difficulty</h2>
          <div className="difficulty-container">
            {['Novice', 'Apprentice', 'Adept', 'Expert', 'Master'].map((option) => (
              <div
                key={option}
                className={`difficulty-box ${option.toLowerCase()} ${selectedDifficulty === option ? 'selected-option' : ''}`}
                onClick={() => handleDifficultyClick(option)}
              >
                {option}
              </div>
            ))}
          </div>
        </div>

        <div className="section-container">
          <h2 className="section-heading">Quiz Type</h2>
          <div className="quiz-types-tile">
            <div className="quiz-types-row">
              <div
                className={`quiz-type-box default-quiz-type-box ${selectedQuizType === 'Default (mixed)' ? 'selected-option' : ''}`}
                onClick={() => handleQuizTypeClick('Default (mixed)')}
              >
                Default (mixed)
              </div>
              <div
                className={`quiz-type-box ${selectedQuizType === 'Multiple Choice' ? 'selected-option' : ''}`}
                onClick={() => handleQuizTypeClick('Multiple Choice')}
              >
                Multiple Choice
              </div>
            </div>
            <div className="quiz-types-row">
              {['True or false', 'Fill in the blank', 'Short answer'].map((type) => (
                <div
                  key={type}
                  className={`quiz-type-box ${selectedQuizType === type ? 'selected-option' : ''}`}
                  onClick={() => handleQuizTypeClick(type)}
                >
                  {type}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="vertical-row">
        <div className="section-container">
          <h2 className="section-heading">Your Suggested Topics</h2>
          <div className="suggested-stars-tile">Suggested Stars Placeholder</div>
        </div>

        <div className="section-container">
          <h2 className="section-heading">Top Community Topics</h2>
          <div className="top-stars-tile">Top Stars Placeholder</div>
        </div>
      </div>

      <div className="vertical-row">
        <div className="quote-container">
          <div className="quote-text">{quote.text}</div>
          <div
            className="quote-author"
            onClick={() => handleAuthorClick(quote.author)}
          >
            — {quote.author}
          </div>
        </div>
        <button
          className={`start-quiz-button ${isStartQuizEnabled ? 'enabled' : 'disabled'}`}
          disabled={!isStartQuizEnabled}
          onClick={handleStartQuiz}
        >
          Start Quiz
        </button>
      </div>
    </div>
  );
}

export default CreateQuiz;