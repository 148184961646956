import React from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { useAuth } from '../helpers/AuthProvider'; // Adjust the path
import './InitialClassroomPage.css';

function InitialClassroomPage() {
  const navigate = useNavigate();
  const { token } = useAuth(); // Access JWT token from AuthProvider

  const handleStartQuiz = async () => {
    const uniqueId = uuidv4();

    if (!token) {
      console.error('User is not authenticated.');
      return;
    }

    try {
      const response = await axios.post(
        '/api/quizzes/',
        {
          classroom_id: uniqueId,
          topic: '', // Default topic
          selected_difficulty: null, // Default difficulty
          selected_quiz_type: 'Default (mixed)', // Default quiz type
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include JWT token
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('Quiz created:', response.data);
      navigate(`/studio/classroom/create/${uniqueId}`);
    } catch (error) {
      console.error('Failed to create quiz:', error.response?.data || error.message);
    }
  };

  return (
    <div className="initial-classroom-container">
      <h1 className="initial-classroom-heading">Welcome to Your Classroom</h1>
      <p className="initial-classroom-description">
        Start creating and managing your quizzes.
      </p>
      <button className="initial-classroom-button" onClick={handleStartQuiz}>
        Create a Quiz
      </button>
    </div>
  );
}

export default InitialClassroomPage;