import React, { useState, useEffect, useRef, createContext, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../helpers/AuthProvider';
import './ClassroomWindow.css';

// Context for Classroom
const ClassroomContext = createContext();

export function useClassroomContext() {
  return useContext(ClassroomContext);
}

function ClassroomWindow() {
  const { user } = useAuth();
  const [showProfileTooltip, setShowProfileTooltip] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const getCsrfToken = () => {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const [name, value] = cookie.trim().split('=');
      if (name === 'csrftoken') {
        return value;
      }
    }
    return null;
  };

  const handleProfileClick = () => {
    setShowDropdown((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  const handleNavigateToProfile = () => {
    window.open('/api/profile/', '_blank');
  };

  const handleSignOut = () => {
    fetch('/api/validate_session/', {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => response.json())
      .then(() => {
        const csrfToken = getCsrfToken();
        const jwt = localStorage.getItem('jwtToken');

        fetch('/api/logout/', {
          method: 'POST',
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            'X-CSRFToken': csrfToken,
            jwt: jwt,
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Logout failed with status: ' + response.status);
            }
            return response.json();
          })
          .then((data) => {
            console.log('Logout response:', data);
            localStorage.removeItem('jwtToken');
            window.location.href = '/';
          })
          .catch((error) => {
            console.error('Logout failed:', error);
          });
      })
      .catch((error) => {
        console.error('Error fetching CSRF token:', error);
      });
  };

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]);

  // Classroom-specific shared context
  const classroomContextValue = {
    user,
    // Add other shared classroom-specific state or functions here
  };

  return (
    <ClassroomContext.Provider value={classroomContextValue}>
      {/* Profile in Top-Right */}
      <div className="profile-container">
        <div
          className="profile-icon"
          onClick={handleProfileClick}
          onMouseEnter={() => setShowProfileTooltip(true)}
          onMouseLeave={() => setShowProfileTooltip(false)}
        >
          {user ? user.fullName[0] : 'U'}
        </div>
        {showProfileTooltip && user && (
          <div className="profile-icon-tooltip">{user.fullName}</div>
        )}
        {showDropdown && (
          <div className="profile-dropdown" ref={dropdownRef}>
            <div className="profile-dropdown-item" onClick={handleNavigateToProfile}>
              Profile
            </div>
            <div className="profile-dropdown-divider"></div>
            <div className="profile-dropdown-item" onClick={handleSignOut}>
              Logout
            </div>
          </div>
        )}
      </div>

      {/* Classroom Content with Outlet for Nested Routes */}
      <div className="classroom-content-inner">
        <Outlet />
      </div>
    </ClassroomContext.Provider>
  );
}

export default ClassroomWindow;