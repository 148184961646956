import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import Sidebar from '../Sidebar/Sidebar';
import ClassroomWindow from './ClassroomWindow';
import { useSidebar } from '../Sidebar/SidebarContext';
import { useAuth } from '../helpers/AuthProvider';
import { ReactComponent as ChatIcon } from '../../assets/images/sidebar-svgrepo-com.svg';
import { ReactComponent as NewChatIcon } from '../../assets/images/new-svgrepo-com.svg';
import { gsap } from 'gsap';

import './Classroom.css';
import '../Sidebar/Sidebar.css';

function Classroom() {
  const { setSidebarContent, refreshSidebarFlag } = useSidebar();
  const { token } = useAuth(); // Access JWT token
  const location = useLocation(); // Access current route location
  const [isSidebarVisible, setIsSidebarVisible] = useState(
    JSON.parse(localStorage.getItem('sidebarVisible')) ?? true
  );
  const [showSidebarTooltip, setShowSidebarTooltip] = useState(false);
  const [showNewChatTooltip, setShowNewChatTooltip] = useState(false);
  const [classroomHistory, setClassroomHistory] = useState([]);
  const [typingTitleClassroomId, setTypingTitleClassroomId] = useState(null);
  const navigate = useNavigate();

  const fetchClassroomHistory = async () => {
    if (!token) {
      console.error('User is not authenticated.');
      return;
    }

    try {
      const response = await fetch('/api/classrooms/', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setClassroomHistory(data);

        // Ensure the latest classroom ID is set for typing animation if coming from CreateQuiz
        if (data.length > 0 && location.state?.fromCreateQuiz) {
          const latestClassroom = data[0]; // Assuming the latest classroom is the first one
          setTypingTitleClassroomId(latestClassroom.classroom_id);

          // Trigger the typing effect after a short delay to ensure the DOM is updated
          setTimeout(() => {
            if (latestClassroom.title) {
              typeOutTitle(latestClassroom.classroom_id, latestClassroom.title);
            }
          }, 100); // Small delay to allow DOM update
        } else {
          setTypingTitleClassroomId(null);
        }
      } else {
        console.error('Failed to fetch classroom history:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching classroom history:', error.message);
    }
  };

  const refreshSidebar = async () => {
    // Wait for a short delay before fetching classroom history
    await new Promise((resolve) => setTimeout(resolve, 500)); // 500ms delay
    await fetchClassroomHistory(); // Refresh the classroom history
  };
  
  const handleNewQuiz = async () => {
    const uniqueId = uuidv4();
  
    if (!token) {
      console.error('User is not authenticated.');
      return;
    }
  
    try {
      const response = await axios.post(
        '/api/quizzes/',
        {
          classroom_id: uniqueId,
          topic: '', // Default topic
          selected_difficulty: null, // Default difficulty
          selected_quiz_type: 'Default (mixed)', // Default quiz type
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include JWT token
            'Content-Type': 'application/json',
          },
        }
      );
  
      console.log('Quiz created:', response.data);
      navigate(`/studio/classroom/create/${uniqueId}`, { state: { fromCreateQuiz: true } });

    } catch (error) {
      console.error('Failed to create quiz:', error.response?.data || error.message);
    }
  };  

  const groupByDate = (items) => {
    const today = new Date();
    const grouped = {
      Today: [],
      Yesterday: [],
      'Previous 7 Days': [],
      'Previous 30 Days': [],
      Older: {},
    };

    items.forEach((item) => {
      const createdAt = new Date(item.created_at);
      const diffInDays = Math.floor((today - createdAt) / (1000 * 60 * 60 * 24));

      if (diffInDays === 0) {
        grouped.Today.push(item);
      } else if (diffInDays === 1) {
        grouped.Yesterday.push(item);
      } else if (diffInDays <= 7) {
        grouped['Previous 7 Days'].push(item);
      } else if (diffInDays <= 30) {
        grouped['Previous 30 Days'].push(item);
      } else {
        const monthYear = createdAt.toLocaleString('default', {
          month: 'long',
          year: 'numeric',
        });
        if (!grouped.Older[monthYear]) {
          grouped.Older[monthYear] = [];
        }
        grouped.Older[monthYear].push(item);
      }
    });

    return grouped;
  };

  const typeOutTitle = (classroomId, title) => {
    const element = document.getElementById(`classroom-title-${classroomId}`);
    if (!element) return;

    let typedTitle = '';
    let index = 0;
    const interval = setInterval(() => {
      if (index < title.length) {
        typedTitle += title[index];
        element.textContent = typedTitle;
        index++;
      } else {
        clearInterval(interval);
      }
    }, 30); // Typing speed (30ms per character)
  };

  const renderGroupedClassrooms = (groupedClassrooms) => {
    return (
      <>
        {Object.entries(groupedClassrooms).map(([section, classrooms]) =>
          section === 'Older' ? (
            Object.entries(classrooms)
              .filter(([, olderClassrooms]) => olderClassrooms.length > 0) // Only include non-empty groups
              .map(([monthYear, olderClassrooms]) => (
                <div key={monthYear}>
                  <h4>{monthYear}</h4>
                  <ul>
                    {olderClassrooms.map((classroom) => (
                      <li
                        key={classroom.classroom_id}
                        onClick={async () => {
                          // Check if the quiz has started
                          if (classroom.has_started) {
                            navigate(`/studio/classroom/quiz/${classroom.classroom_id}`);
                          } else {
                            navigate(`/studio/classroom/create/${classroom.classroom_id}`);
                          }
                          await refreshSidebar(); // Refresh the sidebar after navigation
                        }}
                        className="classroom-history-item"
                      >
                        <span
                          id={`classroom-title-${classroom.classroom_id}`}
                          className={
                            classroom.classroom_id === typingTitleClassroomId
                              ? 'typing-title'
                              : ''
                          }
                        >
                          {classroom.classroom_id === typingTitleClassroomId
                            ? '' // Empty during typing animation
                            : classroom.title || classroom.topic || `Classroom ${classroom.classroom_id}`}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))
          ) : classrooms.length > 0 ? ( // Only include non-empty groups
            <div key={section}>
              <h4>{section}</h4>
              <ul>
                {classrooms.map((classroom) => (
                  <li
                    key={classroom.classroom_id}
                    onClick={async () => {
                      // Check if the quiz has started
                      if (classroom.has_started) {
                        navigate(`/studio/classroom/quiz/${classroom.classroom_id}`);
                      } else {
                        navigate(`/studio/classroom/create/${classroom.classroom_id}`);
                      }
                      await refreshSidebar(); // Refresh the sidebar after navigation
                    }}
                    className="classroom-history-item"
                  >
                    <span
                      id={`classroom-title-${classroom.classroom_id}`}
                      className={
                        classroom.classroom_id === typingTitleClassroomId
                          ? 'typing-title'
                          : ''
                      }
                    >
                      {classroom.classroom_id === typingTitleClassroomId
                        ? '' // Empty during typing animation
                        : classroom.title || classroom.topic || `Classroom ${classroom.classroom_id}`}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          ) : null
        )}
      </>
    );
  };  

  useEffect(() => {
    fetchClassroomHistory();
  }, [token]);

  useEffect(() => {
    fetchClassroomHistory(); // Refresh when refreshSidebarFlag changes
  }, [refreshSidebarFlag]);

  useEffect(() => {
    if (typingTitleClassroomId) {
      const latestClassroom = classroomHistory.find(
        (classroom) => classroom.classroom_id === typingTitleClassroomId
      );
      if (latestClassroom && latestClassroom.title) {
        typeOutTitle(latestClassroom.classroom_id, latestClassroom.title);
      }
    }
  }, [typingTitleClassroomId, classroomHistory]);

  useEffect(() => {
    const logoContainer = document.getElementById('studio-logo-container');
    if (logoContainer) {
      logoContainer.classList.add('studio-logo-hidden');
    }

    return () => {
      if (logoContainer) {
        logoContainer.classList.remove('studio-logo-hidden');
      }
    };
  }, []);

  useEffect(() => {
    if (isSidebarVisible) {
      setSidebarContent(
        <div>
          <div className="sidebar-icon-container">
            <div
              className="sidebar-close-icon-container"
              onMouseEnter={() => setShowSidebarTooltip(true)}
              onMouseLeave={() => setShowSidebarTooltip(false)}
              onClick={() => setIsSidebarVisible((prev) => !prev)}
            >
              <ChatIcon className="sidebar-icon chat-svg-icon" />
              {showSidebarTooltip && (
                <div
                  className="sidebar-tooltip fade-in"
                  onMouseEnter={() => setShowSidebarTooltip(false)}
                >
                  Close Sidebar
                </div>
              )}
            </div>
            <div
              className="chat-icon-container"
              onMouseEnter={() => setShowNewChatTooltip(true)}
              onMouseLeave={() => setShowNewChatTooltip(false)}
              onClick={handleNewQuiz} // Call handleNewQuiz when clicked
            >
              <NewChatIcon className="sidebar-icon settings-svg-icon" />
              {showNewChatTooltip && (
                <div
                  className="chat-tooltip fade-in"
                  onMouseEnter={() => setShowNewChatTooltip(false)}
                >
                  New Quiz
                </div>
              )}
            </div>
          </div>

          {/* Add classroom history to the sidebar */}
          <div className="classroom-history">
            {classroomHistory.length > 0 ? (
              renderGroupedClassrooms(groupByDate(classroomHistory))
            ) : (
              <p>
                It’s a fresh start! Create your first quiz and begin stacking up those
                achievements!
              </p>
            )}
          </div>
        </div>
      );
    } else {
      setSidebarContent('');
    }
  }, [
    isSidebarVisible,
    setSidebarContent,
    showSidebarTooltip,
    showNewChatTooltip,
    classroomHistory,
    navigate,
  ]);

  useEffect(() => {
    localStorage.setItem('sidebarVisible', JSON.stringify(isSidebarVisible));
  }, [isSidebarVisible]);

  return (
    <div className="classroom-container" style={{ display: 'flex', height: '100vh' }}>
      <div
        style={{
          width: isSidebarVisible ? '240px' : '0px',
          transition: 'width 0.3s ease',
        }}
      >
        {isSidebarVisible && <Sidebar />}
      </div>
      <div className="classroom-content" style={{ flexGrow: 1 }}>
        {/* Top Classroom Options */}
        <div className={`top-classroom-options ${isSidebarVisible ? 'hidden' : ''}`}>
          {!isSidebarVisible && (
            <div
              className="sidebar-open-icon-container"
              onMouseEnter={() => setShowSidebarTooltip(true)}
              onMouseLeave={() => setShowSidebarTooltip(false)}
              onClick={() => setIsSidebarVisible((prev) => !prev)}
            >
              <ChatIcon className="sidebar-icon chat-svg-icon" />
              {showSidebarTooltip && (
                <div
                  className="sidebar-tooltip fade-in"
                  onMouseEnter={() => setShowSidebarTooltip(false)}
                >
                  Open Sidebar
                </div>
              )}
            </div>
          )}
          <div
            className="chat-icon-container"
            onMouseEnter={() => setShowNewChatTooltip(true)}
            onMouseLeave={() => setShowNewChatTooltip(false)}
            onClick={handleNewQuiz} // Call handleNewQuiz when clicked
          >
            <NewChatIcon className="sidebar-icon settings-svg-icon" />
            {showNewChatTooltip && (
              <div
                className="chat-tooltip fade-in"
                onMouseEnter={() => setShowNewChatTooltip(false)}
              >
                New Quiz
              </div>
            )}
          </div>
        </div>
        {/* Classroom Window with Outlet for nested routes */}
        <ClassroomWindow>
          <Outlet />
        </ClassroomWindow>
      </div>
    </div>
  );
}

export default Classroom;